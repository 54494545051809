@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);

.writeIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  margin: auto 5px;
}

.writeTitle{
  width: 100%;
  padding: 5px 10px;
  margin: 0px 0px 0px 5px;
  border: none;
  font-size: 30px;
}

.writeTitle:focus, .writeText:focus{
  outline:none;
}

.writeText {
  font-size: 20px;
  height: 100vh;
  width: 100%;
  border: none;
  padding: 5px;
  margin-top: 10px;
  height: 60vh;
}
* {
  /* font-family: 'Dancing Script', cursive; */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}
#video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -5;
}

.zen {
  visibility: hidden;
}
