@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

* {
  /* font-family: 'Dancing Script', cursive; */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}