
.writeIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: auto 5px;
}

.writeTitle{
  width: 100%;
  padding: 5px 10px;
  margin: 0px 0px 0px 5px;
  border: none;
  font-size: 30px;
}

.writeTitle:focus, .writeText:focus{
  outline:none;
}

.writeText {
  font-size: 20px;
  height: 100vh;
  width: 100%;
  border: none;
  padding: 5px;
  margin-top: 10px;
  height: 60vh;
}